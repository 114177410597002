import { Edit, SimpleForm } from "react-admin";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
import { ComboFields } from "./create";

export const ComboEdit = ({ ...props }) => {
  return (
    <Edit {...props} title=" " actions={<ActionsBack redirect={"list"} />}>
      <SimpleForm>
        <ComboFields mode="edit" {...props} />
      </SimpleForm>
    </Edit>
  );
};
