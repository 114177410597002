import {
    TopToolbar,
    EditButton,
} from 'react-admin';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import BackButton from './BackButton';
import { useTranslate } from 'ra-core';

export const ActionsBackEdit = ({ basePath, data, resource, ...props }) => {
    const translate = useTranslate();
    return (
        <TopToolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BackButton
                startIcon={<ChevronLeft />}
                color='primary'
            >
                {translate("ra.action.back")}
            </BackButton>
            <EditButton basePath={basePath}
                record={data}
                resource={resource}
                undoable={false} />
        </TopToolbar>
    )
}