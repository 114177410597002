import { useEffect, useState } from "react";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
import Board from "./board";
import { Button, Typography, Box, Toolbar, Card } from "@material-ui/core";
import { useNotify } from "react-admin";
import { Close } from "@material-ui/icons";
import BackButton from "../../components/Toolbar/BackButton";
import { useTranslate } from "ra-core";

const transform = (data) => {
  let result = {};
  let combos = {};
  combos = data.combos;

  let columns = {};
  data?.media?.forEach((media, index) => {
    let aux = media;
    aux.admedia_id = aux.id;
    aux.id = `column-${index + 1}`;
    aux.title = aux.name;

    columns = { ...columns, [`column-${index + 1}`]: aux };
  });
  let columnOrder = [];
  Object.values(columns).map((i) => columnOrder.push(i.id));
  if (Object.keys(combos).length) {
    result = { ...result, combos: combos };
  } else {
    result = { ...result, combos: null };
  }
  if (Object.keys(columns).length) {
    result = { ...result, columns: columns };
  } else {
    result = { ...result, columns: null };
  }
  if (Object.keys(columnOrder).length) {
    result = { ...result, columnOrder: columnOrder };
  } else {
    result = { ...result, columnOrder: null };
  }
  return result;
};

const transformValues = (values) => {
  let body = {};
  body.columnOrder = [];
  values.columnOrder.forEach((key) => {
    let aux = values.columns[key].admedia_id;
    body.columnOrder.push(Number(aux));
  });
  body.column = [];
  Object.keys(values.columns).forEach((key) => {
    let aux = values.columns[key].combosIds.map((i) => Number(i));
    if (aux != null) {
      body.column[`${values.columns[key].admedia_id}`] = aux;
    }
  });

  return body;
};

export const CombosOrder = () => {
  const [data, setData] = useState();
  const [values, setValues] = useState();
  const notify = useNotify();
  const translate = useTranslate();

  const getCombos = async () => {
    let res = await httpClient(apiUrl + "/combos/all");
    let response = await res.json;
    setData(transform(response));
  };

  const handleSubmit = async () => {
    let body = transformValues(values);
    let res = await httpClient(apiUrl + "/combos/set-order", {
      method: "POST",
      body: JSON.stringify(body),
    });
    let response = await res.json;
    notify(response, "info");
  };

  useEffect(() => {
    getCombos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || data.length === 0) return null;
  return (
    <Box mt="1em">
      <Card>
        <Box p="1em" boxSizing="border-box">
          <Box
            display="flex"
            alignItems="center"
            marginLeft="8px"
            marginBottom="8px"
          >
            <Typography variant="h6">{translate("resources.combos.order")}</Typography>
          </Box>
          <Box marginLeft="8px" marginBottom="16px">
            <Typography variant="body2">
              {" "}
              {translate("resources.combos.define")}
              {" "}
            </Typography>
          </Box>
          <Board initialData={data} setValues={setValues} />
        </Box>
        <Toolbar
          style={{
            background: "#f5f5f5",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BackButton>
            <Close />
            {translate("ra.action.cancel")}
          </BackButton>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {translate("ra.action.save")}
          </Button>
        </Toolbar>
      </Card>
    </Box>
  );
};
