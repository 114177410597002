import * as React from "react";
import {
  BooleanInput,
  Create,
  DateInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import ImagePicker from "../../components/pickers/ImagePicker";
import { ImageField } from "./ImageField";

export const PollsCreate = (props) => {
  const date = new Date().toISOString().substr(0, 10);
  return (
    <Create title=" " {...props}>
      <SimpleForm>
        <ImagePicker permissions={props.permissions} />
        <DateInput
          resource="polls"
          source="date_question"
          validate={[required()]}
          defaultValue={date}
        />
        <TextInput
          resource="polls"
          source="question"
          fullWidth
          validate={[required()]}
        />
        <ImageField source="media_id" resource="polls" />
        <TextInput
          resource="polls"
          source="option_1"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          resource="polls"
          source="option_2"
          fullWidth
          validate={[required()]}
        />
        <TextInput resource="polls" source="option_3" fullWidth />
        <TextInput resource="polls" source="option_4" fullWidth />
        <TextInput resource="polls" source="option_5" fullWidth />
        <BooleanInput label="Activo" source="active" />
      </SimpleForm>
    </Create>
  );
};
