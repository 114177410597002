import * as React from "react";
import { CreateButton, ListBase, Pagination, TopToolbar } from "react-admin";
import { Box, useMediaQuery } from "@material-ui/core";
import GridList from "./GridList";
import { checkPermissions } from "../../utils/validations";

const ListActions = ({ permissions }) => {
  return (
    <TopToolbar>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Box>
          {/* <BenefitsFilter context="form" /> */}
          {checkPermissions(permissions, "create-benefits") ? (
            <CreateButton variant="contained" size="medium" />
          ) : null}
        </Box>
      </Box>
    </TopToolbar>
  );
};

const BenefitsListView = ({ permissions, isSmall, ...props }) => {
  return (
    <>
      <ListActions permissions={permissions} />

      <Box display="flex">
        <Box width={isSmall ? "auto" : "100%"}>
          <GridList permissions={permissions} />
          <Pagination rowsPerPageOptions={[20, 40, 60]} />
        </Box>
      </Box>
    </>
  );
};

export const BenefitsList = ({ ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <ListBase perPage={20} {...props}>
      <BenefitsListView isSmall={isSmall} {...props} />
    </ListBase>
  );
};
