import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Column } from "./column";

const InnerList = ({ column, combosMap, index }) => {
    const combos =  column?.combosIds?.map(comboId => combosMap.find(i => i.id == comboId));
  return <Column column={column} combos={combos} index={index} />;
};

const Board = ({ initialData, setValues }) => {
  const [state, setState] = useState();

  useEffect(() => {
    setState(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && setValues){
        setValues(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);


  if (!state) return null;

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    //** limit only change order of elements of same column */
    if (destination.droppableId !== source.droppableId){
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      };
      setState(newState);
      return;
    }

    const home = state.columns[source.droppableId];
    const foreign = state.columns[destination.droppableId];

    if (home === foreign) {
      const newCombosIds = Array.from(home.combosIds);
      newCombosIds.splice(source.index, 1);
      newCombosIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        combosIds: newCombosIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newHome.id]: newHome,
        },
      };

      setState(newState);
      return;
    }

    // moving from one list to another
    const homeCombosIds = Array.from(home.combosIds);
    homeCombosIds.splice(source.index, 1);
    const newHome = {
      ...home,
      combosIds: homeCombosIds,
    };

    const foreignCombosIds = Array.from(foreign.combosIds);
    foreignCombosIds.splice(destination.index, 0, draggableId);
    const newForeign = {
      ...foreign,
      combosIds: foreignCombosIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };
    setState(newState);
  };
  if (!state) return null;
  return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(provided) => (
            <div
              style={{ display: "flex" }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {state.columnOrder.map((columnId, index) => {
                const column = state.columns[columnId];
                return (
                  <InnerList
                    key={column.id}
                    column={column}
                    combosMap={state.combos}
                    index={index}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    
  );
};

export default Board;
