import * as React from "react";
import {
  CreateButton,
  Filter,
  ListBase,
  Pagination,
  SearchInput,
  TopToolbar,
} from "react-admin";
import { Box, useMediaQuery } from "@material-ui/core";
import GridList from "./GridList";

export const NewspapersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <ListBase perPage={20} sort={{ field: "id", order: "DESC" }} {...props}>
      <NewspapersListView isSmall={isSmall} />
    </ListBase>
  );
};

const NewspapersListView = ({ isSmall }) => {
  return (
    <>
      <ListActions />
      <Box display="flex">
        <Box width={isSmall ? "auto" : "100%"} margin="8px">
          <GridList />
          <Pagination rowsPerPageOptions={[20, 40, 60]} />
        </Box>
      </Box>
    </>
  );
};

const NewspapersFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const ListActions = () => (
  <TopToolbar>
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box m={1}>
        <NewspapersFilter context="form" />
      </Box>
      <Box display="flex" alignItems="center" m={1}>
        <Box>
          <CreateButton
            basePath="/newspapers"
            variant="contained"
            size="medium"
          />
        </Box>
      </Box>
    </Box>
  </TopToolbar>
);
