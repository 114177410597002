import * as React from "react";
import { FormDataConsumer, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";

export default function AlertHasClubCard(props) {
  const translate = useTranslate();
  const dataSubscribers = useSelector(
    (state) => state.admin.resources.users.data
  );
  return (
    <FormDataConsumer subscription={{ values: true }}>
      {({ formData, ...rest }) =>
        props.record &&
        props.record.user_id !== formData.user_id &&
        formData.type === "titular" &&
        formData.user_id &&
        dataSubscribers &&
        dataSubscribers[formData.user_id] &&
        dataSubscribers[formData.user_id].clubcards_count > 0 && (
          <Alert
            style={{ marginBottom: "1em", display: "inline-flex" }}
            severity="warning"
          >
            {translate("resources.club_cards.info_has_card")}
          </Alert>
        )
      }
    </FormDataConsumer>
  );
}
