import { Typography } from "@material-ui/core";
import * as React from "react";
import {
  ImageField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { apiUrl } from "../../utils/utilsFetch";

export const openPdf = (e, setLoading) => {
  e.preventDefault();
  const fileRef = e.target.href;
  if (
    fileRef &&
    apiUrl &&
    apiUrl.replace(/^https?:\/\//, "").split("/")[0] !==
      fileRef.replace(/^https?:\/\//, "").split("/")[0]
  ) {
    return window.open(fileRef, "_blank");
  }
  const token = localStorage.getItem("token");
  fetch(fileRef, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      setLoading(false);
      const file = new Blob([blob], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      // Open new windows and show PDF
      window.open(fileURL, "_blank");
    })
    .catch(function (e) {
      console.log(e);
      setLoading(false);
    });
};

const FileNewspaperField = ({ record }) => {
  const [loading, setLoading] = React.useState(false);

  return record ? (
    <span>
      {record.file_url && (
        <div>
          <Typography
            variant="h6"
            style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "12px" }}
          >
            Link
          </Typography>
          <a
            href={record.file_url}
            title={record.date_publish}
            onClick={(e) => {
              openPdf(e, setLoading);
            }}
          >
            {record.date_publish}
          </a>
          {loading && <p>Cargado...</p>}
        </div>
      )}
    </span>
  ) : null;
};

export const NewspapersShow = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField disabled source="id" fullWidth />
      <TextField source="date_publish" fullWidth />
      <TextField source="title" fullWidth />
      <ReferenceField
        source="category_id"
        reference="newspapers_categories"
        label="resources.newspapers.fields.category"
      >
        <TextField source="name" />
      </ReferenceField>
      <ImageField source="thumbnail_url" title="title" />
      <br />
      <FileNewspaperField source="file_url" title="title" target="_blank" />
    </SimpleShowLayout>
  </Show>
);
