import { useEffect } from "react";
import { RadioButtonGroupInput } from "react-admin";
import { useField } from "react-final-form";

export const CustomRadio = ({ source, label, choices }) => {
    const { input: radio } = useField(source);
  
    useEffect(() => {
      if (!radio?.value) {
        let res = choices.find((i) => i?.checked);
        radio.onChange(res?.id);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    return (
      <RadioButtonGroupInput source={source} label={label} choices={choices} />
    );
  };
