import * as React from "react";
import { useCreateSuggestionContext, useCreate } from "react-admin";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from "@material-ui/core";
import { useTranslate } from "ra-core";

export const CreateCategory = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");
  const [create] = useCreate("newspapers_categories");
  const translate = useTranslate();
  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      {
        payload: {
          data: {
            name: value,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue("");
          onCreate(data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle style={{ paddingBottom: '8px' }}>{translate("components.add") + " " + translate("components.category")} </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ paddingTop: 0, paddingBottom: '16px' }}>
          <TextField
            label="Nombre categoría"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between", padding: '16px 24px' }}
        >
          <Button variant="outlined" onClick={onCancel}>
            {translate("ra.action.cancel")}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {translate("ra.action.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
