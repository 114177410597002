import * as React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  required,
  DateInput,
  BooleanInput,
  TextInput,
  SelectInput,
  FormDataConsumer,
  SaveButton,
  Toolbar,
  AutocompleteInput,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { STATUS } from "../../utils/cardStatus";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
import AlertHasClubCard from "./alertHasClubCard";
import { validateUserDNI } from "./validateUserDNI";
import { useSelector } from "react-redux";
import { useTranslate } from "ra-core";
const ClubCardEditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );
};

export const ClubCardEdit = (props) => {
  const dataSubscribers = useSelector(
    (state) => state.admin.resources.users.data
  );
  const translate = useTranslate();

  return (
    <Edit title=' ' actions={<ActionsBack />} undoable={false} {...props}>
      <SimpleForm toolbar={<ClubCardEditToolbar />}>
        <Box mt={1} mb={1}>
          <SelectInput
            source='type'
            resource='club_cards'
            choices={[
              { id: "titular", name: "Titular" },
              { id: "extension", name: "Extensión" },
            ]}
            defaultValue={"titular"}
            validate={required()}
            fullWidth
          />
        </Box>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === "titular" && (
              <>
                <Box mt={1} mb={1}>
                  <Typography variant='h6'>Suscriptor</Typography>
                </Box>
                <ReferenceInput
                  source={"user_id"}
                  reference='users'
                  validate={[
                    required(),
                    (value) => {
                      return validateUserDNI(dataSubscribers[value]);
                    },
                  ]}
                  filterToQuery={(searchText) => ({
                    search: searchText,
                    has_club: true,
                  })}
                  perPage={20}
                  {...props}
                >
                  <AutocompleteInput resettable optionText={"username"} />
                </ReferenceInput>
              </>
            )
          }
        </FormDataConsumer>
        <AlertHasClubCard />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === "extension" && (
              <>
                <Box mt={1} mb={1}>
                  <Typography variant='h6'>Suscriptor</Typography>
                </Box>
                <ReferenceInput
                  source={"headline"}
                  reference='club_cards'
                  filterToQuery={(searchText) => ({
                    search: searchText,
                    headline: null,
                  })}
                  perPage={20}
                  validate={[required()]}
                  {...props}
                >
                  <SelectInput optionText={"username"} />
                </ReferenceInput>
                <Box
                  display={{ xs: "block", sm: "flex" }}
                  width={{ xs: "100%!important", md: "60%!important" }}
                >
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source='first_name'
                      resource='club_cards'
                      validate={[required()]}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source='last_name'
                      resource='club_cards'
                      validate={[required()]}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1}>
                    <TextInput
                      source='dni'
                      resource='club_cards'
                      validate={[required()]}
                    />
                  </Box>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1}>
                    <DateInput
                      source='birthday'
                      resource='club_cards'
                      validate={[required()]}
                    />
                  </Box>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1}>
                    <TextInput
                      source='email'
                      resource='club_cards'
                      type='email'
                      validate={[required()]}
                    />
                  </Box>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1}>
                    <TextInput
                      source='phone'
                      resource='club_cards'
                      validate={[required()]}
                    />
                  </Box>
                </Box>
                <Typography variant='h6' gutterBottom>
                  {translate("components.address")}
                </Typography>
                <Box
                  display={{ xs: "block", sm: "flex" }}
                  width={{ xs: "100%!important", md: "60%!important" }}
                >
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source='province'
                      resource='club_cards'
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source='city'
                      resource='club_cards'
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box
                  display={{ xs: "block", sm: "flex" }}
                  width={{ xs: "100%!important", md: "60%!important" }}
                >
                  <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source='address'
                      resource='club_cards'
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source='addr_floor'
                      resource='club_cards'
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source='addr_door'
                      resource='club_cards'
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                </Box>
              </>
            )
          }
        </FormDataConsumer>

        <Box mt={1} mb={1}>
          <Typography variant='h6'>{translate("components.card")}</Typography>
        </Box>
        <BooleanInput
          source='printed'
          resource='club_cards'
          initialvalue={false}
        />
        <DateInput source='delivery_date' resource='club_cards' />
        <SelectInput
          source='state'
          resource='club_cards'
          choices={STATUS}
          defaultValue={"request"}
        />
      </SimpleForm>
    </Edit>
  );
};
