import * as React from "react";
import { useUpdate } from "react-admin";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Box,
} from "@material-ui/core";
import { useTranslate } from "ra-core";

export const EditCategory = ({ open, setOpen, data }) => {
  const [values, setValue] = React.useState(data || "");
  const [update] = useUpdate("newspapers_categories");
  const translate = useTranslate();
  const handleSubmit = (event) => {
    event.preventDefault();
    update(
      {
        payload: {
          id: values?.id,
          data: values,
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue("");
          setOpen(false);
        },
      }
    );
  };

  React.useEffect(() => {
    setValue(data);
  }, [data])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ paddingBottom: "8px" }}>
      {translate("components.edit_category")}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ paddingTop: 0, paddingBottom: "16px" }}>
          <Box display="flex" flexDirection="column" style={{ gap: "1em" }}>
            <TextField
              label={translate("components.category_name")}
              value={values?.name}
              onChange={(event) =>
                setValue({ ...values, name: event.target.value })
              }
              autoFocus
            />
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <Button variant="outlined" onClick={() => setOpen(false)}>
            {translate("ra.action.cancel")}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {translate("ra.action.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
