import { Datagrid, List, TextField, TextInput, Filter } from "react-admin";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import { checkPermissions } from "../../utils/validations";
import { useTranslate } from "ra-core";
const CustomFilters = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
  </Filter>
);

export const AdMediaList = (props) => {
  // Chequear permisos
  const canEdit = checkPermissions(props.permissions, 'edit-admedia');
  const translate = useTranslate();
  return (
    <List {...props} title=" " bulkActionButtons={false} filters={<CustomFilters />} actions={<ActionsCreate />}>
      <Datagrid rowClick={() => canEdit ? "edit" : ""}>
        <TextField source="name" label={translate("components.name")} />
      </Datagrid>
    </List >
  );
};
