import {
    TopToolbar, useRedirect
} from 'react-admin';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import BackButton from './BackButton';
import { useTranslate } from "ra-core";

export const ActionsBack = ({ redirect, basePath, ...props }) => {
    const goredirect = useRedirect();
    const handleClick = () => {
        goredirect(redirect, basePath);
    }
    const translate = useTranslate();
    return (<TopToolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton
            startIcon={<ChevronLeft />}
            color='primary'
            handleClick={redirect ? handleClick : undefined}
        >
            {translate("ra.action.back")}
        </BackButton>
    </TopToolbar>
    );
}