import * as React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  FunctionField,
  useTranslate,
} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";

const PollsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
  </Filter>
);

export const PollsList = (props) => {
  const translate = useTranslate();
  return (
    <List
      filters={<PollsFilter />}
      actions={<ActionsCreate label={`${translate("resources.polls.new")}`} />}
      title=" "
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="date_question" />
        <TextField source="question" />
        <FunctionField
          source="active"
          render={(record) =>
            record && record.active === 1 ? <CheckIcon /> : ""
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
