import React, { Fragment, useState } from 'react';
import { useDispatch } from "react-redux";
import {
    Button,
    useUpdateMany,
    useUnselectAll,
    showNotification,
    useRefresh,
    Confirm,
    useTranslate
} from 'react-admin';
import PrintIcon from '@material-ui/icons/Print';

export default function PrintedButton({ selectedIds }) {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const unselectAll = useUnselectAll();
    const translate = useTranslate();

    const [updateMany, { loading }] = useUpdateMany(
        'club_cards',
        selectedIds,
        { printed: true },
        {
            onSuccess: (data) => {
                refresh();
                dispatch(
                    showNotification("resources.club_cards.notification.success", "success"),
                    unselectAll('club_cards')
                );
            },
            onFailure: (error) => {
                dispatch(showNotification("resources.club_cards.notification.error", "error"));
            },
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button
                label={translate('resources.club_cards.bulk_actions.print_label')}
                disabled={loading}
                onClick={handleClick}
                style={{ marginRight: '1em' }}
            >
                <PrintIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('resources.club_cards.bulk_actions.print_confirm_title')}
                content={translate('resources.club_cards.bulk_actions.print_confirm_content')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}