import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    DateField,
    BooleanField,
    TextField,
    SelectField,
    FunctionField,
    Datagrid,
    ReferenceManyField,
    Labeled
} from 'react-admin';
import { Button, Typography } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from "react-router-dom";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
import { ActionsBackEdit } from "../../components/Toolbar/ActionsBackEdit";
import { STATUS } from "../../utils/cardStatus";

const ButtonShow = ({ record }) => {
    if (!record) return null;

    return (
        <Button
            startIcon={<VisibilityIcon />}
            color="primary"
            component={Link}
            to={{
                pathname: `/club_cards/${record.id}/show`,
            }}
        >
            Ver
        </Button>
    )

};

const RelationshipCardsField = ({ record }) => {
    if (!record) return null;
    if (record.type === "extension") {
        return (
            <Labeled label="Tarjeta titular">
                <ReferenceManyField
                    source="headline"
                    target="id"
                    reference="club_cards"
                >
                    <Datagrid>
                        <TextField resource="club_cards" source="username" />
                        <TextField resource="club_cards" source="dni" />
                        <DateField resource="club_cards" source="delivery_date" />
                        <BooleanField resource="club_cards" source="active" />
                        <ButtonShow record={record => record} />
                    </Datagrid>
                </ReferenceManyField>
            </Labeled>
        )
    }
    return (
        <Labeled label="Extensiones asociadas">
            <ReferenceManyField
                reference="club_cards"
                target="headline"
                label="Extensiones"
            >
                <Datagrid>
                    <TextField resource="club_cards" source="username" />
                    <TextField resource="club_cards" source="dni" />
                    <DateField resource="club_cards" source="delivery_date" />
                    <BooleanField resource="club_cards" source="active" />
                    <ButtonShow record={record => record} />
                </Datagrid>
            </ReferenceManyField>
        </Labeled>
    )
};

const SectionTitle = ({ record }) => {
    if (!record) return null;
    return (
        <Typography variant="h6" gutterBottom>
            {record.type === "extension" ? "Tarjeta Extensión" : "Tarjeta Titular"}
        </Typography>
    );
};

export const ClubCardShow = ({ permissions, ...props }) => {

    return (
        <Show
            title=" "
            resource="club_cards"
            actions={permissions && permissions.some(permission => permission.name === '*' || permission.name === 'edit-club-cards') ? <ActionsBackEdit {...props} /> : <ActionsBack {...props} />}
            {...props}
        >
            <SimpleShowLayout>
                <SectionTitle record={record => record} />
                <ReferenceField
                    {...props}
                    source={"user_id"}
                    reference='subscribers'
                    resource="club_cards"
                >
                    <FunctionField render={record => record.first_name + ' ' + record.last_name} />
                </ReferenceField>
                <TextField source="dni" resource="club_cards" />

                <SelectField source="state" resource="club_cards" choices={STATUS} />
                <DateField source="delivery_date" resource="club_cards" />
                <BooleanField source="printed" resource="club_cards" />
                <BooleanField source="active" resource="club_cards" />
                <RelationshipCardsField record={record => record} />

            </SimpleShowLayout>
        </Show>
    )
};