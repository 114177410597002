import { lighten } from "../../../utils/sassFunction";

export const colors = [
    { name: "primary", title: "Primario", value: '#009ada' },
    { name: "primaryHover", title: "Primario alternativo", value: `${lighten("#009ada", 10)}` },
    { name: "warning", title: "Amarillo", value: `${lighten('#dc901a', 10)}` },
    { name: "grey", title: "Gris", value: '#989898' },
    { name: "dark", title: "Oscuro", value: `${lighten('#404040', 10)}` },
];

export const colorsalert = [
    { name: "light", title: "Default", value: `${lighten("#6f6f6f", 48)}` },
    { name: "success", title: "Suceso", value: '#98bf17' },
    { name: "warning", title: "Alerta", value: `${lighten('#dc901a', 10)}` },
    { name: "danger", title: "Peligro", value: '#b9000b' },
];