import { FunctionField } from "react-admin"
import { STATE_TRANSLATIONS } from "../../utils/cardStatus";
import Chip from '@material-ui/core/Chip';

const StateField = (props) => {

  if(!props.record) return null;
  const { state } = props.record;

  return (
    <FunctionField
      render={() =>
        STATE_TRANSLATIONS[state] ?
          <Chip
            size="small"
            style={STATE_TRANSLATIONS[state].chip}
            label={STATE_TRANSLATIONS[state].name}
          />
          :
          <Chip
            size="small"
            label={state}
          />
      }
      {...props}
    />
  )
}

export default StateField;