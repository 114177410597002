import React from "react";
import { FieldArray } from "react-final-form-arrays";
import Add from "@material-ui/icons/Add";
import { useField } from "react-final-form";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Typography, Tooltip } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import InfoIcon from "@material-ui/icons/Info";
import { useFormState } from "react-final-form";
import { useTranslate } from "ra-core";

const customValidate = (value, max) => {
  let error = "";
  let cleanText = value?.replace(/<\/?[^>]+(>|$)/g, "");
  if (!cleanText) {
    error = "ra.validation.required";
  }
  if (cleanText && max && cleanText?.length > max) {
    error = `Debe contener ${max} caracteres o menos`;
  }
  return error;
};

const getContChars = (values,index,max) =>{
  let result = "";
  if (values[index] !== undefined){
    let text = values[index]?.description;
    let cleanText = text?.replace(/<\/?[^>]+(>|$)/g, "");
    result = `. Caracteres ${cleanText?.length || 0} / ${max}`;
  }
  return result;

}

const CustomIterator = ({ source, label, maxLengthRichText, tooltip = "resources.plans.tooltips.lines_description" , maxLines}) => {
  const { input } = useField(source);
  const { values } = useFormState();
  const description = values?.lines_description;
  const translate = useTranslate();
  const onDragEnd = (result, provided) => {
    const { source, destination } = result;
    if (destination) {
      // Get the item
      const item = input.value[source.index];

      // Remove item from array
      const newArray = input.value.filter(
        (el, index) => index !== source.index
      );

      // Insert item at destination
      newArray.splice(destination.index, 0, item);
      input.onChange(newArray);
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
        gutterBottom
      >
        {label}
        <Tooltip title={translate(tooltip)}>
          <InfoIcon style={{ marginLeft: ".5em" }} color="primary" />
        </Tooltip>
      </Typography>
      <FieldArray name="lines_description">
        {(fieldProps) => (
          <DragDropContext onDragEnd={onDragEnd}>
            <TableContainer style={{ marginTop: "1em" }}>
              <Table aria-label="descriptions list">
                <Droppable droppableId="droppable-descriptions">
                  {(provided, snapshot) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {fieldProps.fields.map((item, index) => (
                        <Draggable
                          key={`d${index}`}
                          draggableId={`d${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <TableCell {...provided.dragHandleProps}>
                                <DragHandleIcon />
                              </TableCell>
                              <TableCell align="left" width="100%">
                                <RichTextInput
                                  label=""
                                  source={`lines_description[${index}].description`}
                                  toolbar={[["bold", "italic", "link"]]}
                                  helperText={`Describí en pocas palabras qué es lo que incluye${maxLengthRichText ? getContChars(description,index,maxLengthRichText) : ""}`}
                                  fullWidth
                                  validate={(values) =>
                                    customValidate(values, maxLengthRichText)
                                  }
                                />
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  style={{
                                    color: "#f50057",
                                    padding: "5px",
                                    border: "1px solid #f50057",
                                    minWidth: "auto",
                                  }}
                                  type="button"
                                  onClick={() =>
                                    fieldProps.fields.remove(index)
                                  }
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
              <Button
                type="button"
                onClick={() => fieldProps.fields.push({ description: "" })}
                color="primary"
                variant="outlined"
                disabled={description && maxLines && description?.length == maxLines ? true : false }
                style={{ marginTop: "1em" }}
                startIcon={<Add />}
              >
                {translate("components.add_line")}
              </Button>
            </TableContainer>
          </DragDropContext>
        )}
      </FieldArray>
    </>
  );
};

export default CustomIterator;
