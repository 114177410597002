import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { useTranslate } from "ra-core";

export const CustomAddButton = (props) => {
  const translate = useTranslate();
  return (
    <Button
      {...props}
      startIcon={<AddIcon />}
      color="primary"
      variant="outlined"
      style={{ marginTop: ".5em" }}
    >
      {props?.label ? props.label : translate("ra.components.add")}
    </Button>
  );
};
