import { Edit, SimpleForm, useTranslate } from "react-admin";
import { AdMediaFields } from "./create";

export const AdMediaEdit = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title=" ">
      <SimpleForm>
        <AdMediaFields
          label={`${translate("resources.amedia.edit")}`}
          {...props}
        />
      </SimpleForm>
    </Edit>
  );
};
